<template>
  <div class="elv-financial-process-table">
    <ag-grid-vue
      v-if="processTableConfig.length > 0"
      :style="{
        height: 'calc(100% - 12px)',
        minWidth: '406px',
        maxWidth: '100%'
      }"
      class="elv-ag-theme-alpine"
      :rowHeight="42"
      :grid-options="gridOptions"
      :column-defs="processTableConfig"
      :loading-cell-renderer="TableLoadingCell"
      :pinned-top-row-data="pinnedTopRowData"
      :loading-cell-renderer-params="loadingCellRendererParams"
      :is-server-side-group-open-by-default="isServerSideGroupOpenByDefault"
      :get-row-id="getRowId"
      :get-row-height="getRowHeight"
      @cellClicked="onCellClicked"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
    />
  </div>
</template>
<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import { AgGridVue } from 'ag-grid-vue3'
import { LicenseManager } from 'ag-grid-enterprise'
import { themeAlpine } from '@ag-grid-community/theming'
import { type GridApi, DetailGridInfo, IServerSideGetRowsParams } from 'ag-grid-enterprise'

import noDataImg from '@/assets/img/noData.png'
import { useEntityStore } from '@/stores/modules/entity'
import { isEmpty, uniqueId, capitalize } from 'lodash-es'
import { useReportStore } from '@/stores/modules/reports/index'
import CustomTooltip from '@/components/Reports/Cell/Tooltips.vue'
import TableLoadingCell from '@/components/Reports/Cell/TableLoadingCell.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

LicenseManager.setLicenseKey(import.meta.env.VITE_TABLE_KEY)

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  height: {
    type: String,
    default: `calc(100vh - 219px)`
  },
  groupName: {
    type: String,
    default: 'name'
  },
  groupCapitalize: {
    type: Boolean,
    default: false
  },
  holdingsParams: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const { t } = useI18n()
const route = useRoute()

const reportStore = useReportStore()
const entityStore = useEntityStore()
const tableConfigStore = useReportsTableConfigStore()
const { processTableConfig } = storeToRefs(tableConfigStore)
const overlayDrawerRef = ref()
const columnData = ref()
const tableDataList: any = ref([])
const transactionEditOverlayRef = ref()
const gridApi = ref<GridApi | null>(null)

const overlayDrawerData: any = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false,
  totalContacts: 0
})

const loadingCellRendererParams = reactive({
  loadingMessage: 'Loading'
})
const groupLitsData: any = reactive({
  group: {},
  listData: {}, // 分组后list的数据
  total: {}, // 总计数据
  totalCount: 0
})

// ag-grid基础配置
const gridOptions: any = reactive({
  sidebar: false,
  tooltipShowDelay: 500,
  headerHeight: 35,
  // tooltipHideDelay: 5000,
  // columnHoverHighlight: true,
  theme: themeAlpine,
  rowModelType: 'serverSide',
  groupDisplayType: 'custom', // custom || groupRows
  suppressRowTransform: true,
  suppressContextMenu: true,
  animateRows: true,
  debounceVerticalScrollbar: false,
  suppressPropertyNamesCheck: true,
  rowGroupPanelShow: true,
  suppressServerSideFullWidthLoadingRow: true, // 骨架加载行
  // serverSideInfiniteScroll: true, // 设置服务器端行模型是否使用无限滚动
  maxConcurrentDatasourceRequests: 1, // 有多少请求同时命中服务器。如果达到最大值，请求将排队
  cacheBlockSize: 50, // 缓存中每个块有多少行，即一次从服务器返回多少行
  blockLoadDebounceMillis: 300, // 加载块之前等待多少毫秒。在无限滚动和滚动许多无限块时很有用，因为它可以防止块加载直到滚动结束。
  serverSideInitialRowCount: 0, // 初始化从第几行开始显示
  defaultColDef: {
    resizable: true,
    suppressMovable: true,
    cellStyle: {
      height: '42px',
      'line-height': '42px' // 行高设置同步：跟rowHeight属性设置值一致
    },
    rowHeight: 42,
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params: any) => {
      return params?.value
    }
  },
  overlayLoadingTemplate: '<span class="ag-overlay-loading-center">data is loading...</span>',
  overlayNoRowsTemplate: `<div class="elv-result">
    <img src="${noDataImg}" style="width: 40px; height: 40px;margin-bottom:16px" />
    <div class="elv-result-description">
      No data matches this filter
    </div>
  </div>`
})

const isGroup = computed(() => {
  return props.type !== 'categorized' && props.type !== 'review' && props.type !== 'contactsUnnamed'
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const isDestroyed = computed(() => {
  return !gridApi.value || gridApi.value?.isDestroyed()
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const onCheckBatchTransactions = () => {
  overlayDrawerData.isBatch = true
  overlayDrawerData.type = 'transaction'
  overlayDrawerData.currentData = {}
  transactionEditOverlayRef.value?.onCheckDrawerStatus()
}

const onFirstDataRendered = (params: any) => {
  if (props.type === 'categorized' || props.type === 'review') {
    nextTick(() => {
      params?.columnApi?.autoSizeColumns([
        'transactions-date-time',
        'transactions-source',
        'transactions-currency',
        'transactions-amount-in',
        'transactions-amount-out',
        'transactions-event'
      ])
      const windowWidth = window.innerWidth
      if (windowWidth >= 1650) {
        params?.api?.sizeColumnsToFit({
          columnLimits: [{ key: 'transactions-from-to' }]
        })
      } else {
        params?.columnApi?.autoSizeColumn({ key: 'transactions-from-to' })
      }
    })
  }
}

const onDeleteTableRow = async (rowIds: string[]) => {
  if (!rowIds.length) return
  rowIds.forEach((itemId) => {
    const rowNode = gridApi.value?.getRowNode(itemId)
    const rowNodeElement: any = document.querySelectorAll(`[row-id="${rowNode?.id}"]`)
    if (rowNode && rowNodeElement) {
      rowNode.setRowHeight(0)
      // rowNode?.setDisplayed(false)
      gridApi.value?.applyServerSideTransaction({
        remove: [{ rowId: rowNode.data.rowId }]
      })
      rowNodeElement[0].style.setProperty('display', 'none', 'important')
      rowNodeElement[0].style.setProperty('border', 'none', 'important')
      rowNodeElement[0].style.setProperty('overflow', 'hidden', 'important')
      rowNodeElement[1].style.setProperty('display', 'none', 'important')
      rowNodeElement[1].style.setProperty('overflow', 'hidden', 'important')
      rowNodeElement[1].style.setProperty('border', 'none', 'important')
    }
    tableDataList.value = []
    gridApi.value?.refreshServerSide()
  })
  groupLitsData.totalCount -= rowIds.length
  groupLitsData.total.count = groupLitsData.totalCount
  // gridApi.value.setRowCount(groupLitsData.totalCount)
}

const onEditTableRowEvent = (data: any) => {
  if (!data.rows.length) return
  data.rows.forEach((item: any) => {
    const rowNode = gridApi.value?.getRowNode(item.rowId)
    if (rowNode) {
      rowNode?.setData({ ...rowNode.data, categoryNo: data.categoryNo })
      groupLitsData.listData?.list.forEach((listItem: any) => {
        if (listItem.rowId === item.rowId) {
          // eslint-disable-next-line no-param-reassign
          listItem.categoryNo = data.categoryNo
        }
      })
    }
  })
}

emitter.on('onEditTableRowEvent', onEditTableRowEvent)
emitter.on('onDeleteTableRow', onDeleteTableRow)

// 单元格点击事件
const onCellClicked = (cell: any) => {
  console.log(cell)
  columnData.value = cell
  overlayDrawerData.currentData = {}
  if (
    cell.value?.tableType === 'transactions' &&
    cell.value?.cellName === 'From/To' &&
    (cell.event?.target?.className === 'elv-financials-cell-fromTo-main__address' ||
      cell.event?.target?.className === 'elv-financials-cell-fromTo-item-main__logo' ||
      cell.event?.target?.className?.baseVal === 'elv-financials-cell-fromTo-item-main__logo' ||
      cell.event?.target?.className === 'elv-financials-cell-fromTo-item-main')
  ) {
    const direction = cell.event?.target?.attributes['data-type'].value
    switch (direction) {
      case 'from':
        if (cell.data?.fromEntityAccount?.address || cell.data?.fromEntityAccount?.cardNumber) {
          overlayDrawerData.drawerTitle = 'Account'
          overlayDrawerData.currentData = {
            paymentIdentifier: cell.data?.fromAddress,
            source: { ...cell.data.source },
            platform: { ...cell.data?.fromEntityAccount?.platform },
            ...cell.data?.fromEntityAccount,
            drawerType: 'fromEntityAccount'
          }
        } else if (cell.data?.fromAddressContact?.role) {
          overlayDrawerData.drawerTitle = 'Contact'
          overlayDrawerData.currentData = {
            paymentIdentifier: cell.data?.fromAddress,
            source: { ...cell.data.source },
            ...cell.data?.fromAddressContact,
            platform: cell.data?.platform,
            drawerType: 'fromAddressContact'
          }
        } else {
          switch (cell.data?.direction) {
            case 'OUT':
              overlayDrawerData.drawerTitle = 'Account'
              overlayDrawerData.currentData = {
                paymentIdentifier: cell.data?.fromAddress,
                platform: cell.data?.platform,
                source: { ...cell.data.source },
                drawerType: 'fromEntityAccount',
                entityAccountId: cell.data?.toEntityAccountId ?? cell.data?.fromEntityAccountId
              }
              break
            default:
              overlayDrawerData.drawerTitle = 'Contact'
              overlayDrawerData.currentData = {
                paymentIdentifier: cell.data?.fromAddress,
                platform: cell.data?.platform,
                source: { ...cell.data.source },
                platformId:
                  cell.data.source?.type === 'CSV' && cell.data.source?.csvFile.templateType === 'BANK'
                    ? 31
                    : cell.data?.platformId,
                drawerType: 'fromAddressContact'
              }
              break
          }
        }
        break
      default:
        if (cell.data?.toEntityAccount?.address) {
          overlayDrawerData.drawerTitle = 'Account'
          overlayDrawerData.currentData = {
            paymentIdentifier: cell.data?.toAddress,
            source: { ...cell.data.source },
            platform: { ...cell.data?.toEntityAccount?.platform },
            ...cell.data?.toEntityAccount,
            drawerType: 'toEntityAccount'
          }
        } else if (cell.data?.toAddressContact?.role) {
          overlayDrawerData.drawerTitle = 'Contact'
          overlayDrawerData.currentData = {
            paymentIdentifier: cell.data?.toAddress,
            source: { ...cell.data.source },
            platform: cell.data?.platform,
            ...cell.data?.toAddressContact,
            drawerType: 'toAddressContact'
          }
        } else {
          switch (cell.data?.direction) {
            case 'IN':
              overlayDrawerData.drawerTitle = 'Account'
              overlayDrawerData.currentData = {
                paymentIdentifier: cell.data?.toAddress,
                source: { ...cell.data.source },
                drawerType: 'toEntityAccount',
                platform: cell.data?.platform,
                entityAccountId: cell.data?.toEntityAccountId ?? cell.data?.fromEntityAccountId
              }
              break
            default:
              overlayDrawerData.drawerTitle = 'Contact'
              overlayDrawerData.currentData = {
                paymentIdentifier: cell.data?.toAddress,
                platform: cell.data?.platform,
                source: { ...cell.data.source },
                platformId:
                  cell.data.source?.type === 'CSV' && cell.data.source?.csvFile.templateType === 'BANK'
                    ? 31
                    : cell.data?.platformId,
                drawerType: 'toAddressContact'
              }
              break
          }
        }
        break
    }
    overlayDrawerData.isBatch = false
    overlayDrawerData.type = 'transaction'
    overlayDrawerRef.value?.onCheckDrawerStatus()
  } else if (
    cell.value?.tableType === 'transactions' &&
    cell.event?.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
    cell?.rowPinned !== 'bottom' &&
    cell?.column?.pinned === null
  ) {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.transaction?.viewDetail
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    overlayDrawerData.isBatch = false
    overlayDrawerData.currentData = cell.data
    transactionEditOverlayRef.value?.onCheckDrawerStatus()
  } else if (
    (((cell.value?.tableType === 'accounts' || cell.value?.tableType === 'contactsNamed') && cell.node?.level !== 0) ||
      cell.value?.tableType === 'contactsUnnamed') &&
    cell.event?.target?.className !== 'elv-financials-cell-address-main__copy'
  ) {
    if (cell.value?.tableType === 'accounts') {
      overlayDrawerData.drawerTitle = 'Account'
      overlayDrawerData.type = 'account'
    } else {
      overlayDrawerData.drawerTitle = 'Contact'
      overlayDrawerData.type = 'contact'
    }
    overlayDrawerData.isBatch = false
    overlayDrawerData.currentData = cell.data
    overlayDrawerRef.value?.onCheckDrawerStatus()
  }
  overlayDrawerData.currentData.rowId = cell.node.id
}

// 分组数据处理
const groupRowDataFormatter = async (params: any) => {
  let data: any = {
    list: [],
    total: {}
  }
  if (!groupLitsData.group?.list?.length) {
    const dateTime = dayjs().subtract(1, 'day').startOf('day')
    const date = dayjs(dateTime).format('YYYY-MM-DD')
    const paramsData = props.holdingsParams?.date !== '' ? props.holdingsParams : { date }
    data = await reportStore.fetchHoldingsDataList(entityId.value, paramsData)
    if (props.type === 'accounts' || props.type === 'contactsNamed') {
      groupLitsData.group.list = data
      groupLitsData.listData = data
      const list = data
      data = { list }
    } else if (props.type === 'holdings') {
      groupLitsData.group.list = data.holdingValues
      groupLitsData.listData = data.holdingValues
      const list = data.holdingValues
      const costBasisFC = data.holdingValues.reduce((acc: number, item: any) => {
        return new Big(acc).plus(item?.costBasis ? new Big(item?.costBasis) : '0')
      }, new Big(0))
      const marketValueFC = data.holdingValues.reduce((acc: number, item: any) => {
        return new Big(acc).plus(item?.marketValue ? new Big(item?.marketValue) : '0')
      }, new Big(0))
      groupLitsData.total = {
        costBasis: costBasisFC.toNumber(),
        marketValue: marketValueFC.toNumber()
      }
      data = { list }
    } else {
      groupLitsData.group = data
      groupLitsData.total = data.total
    }
    if (data?.totalCount) groupLitsData.totalCount = data.totalCount
  } else if (params.request?.endRow && params.request.startRow !== 0 && params.request.endRow !== 100) {
    if (params.request.endRow / 30) {
      data.list = groupLitsData.group?.list?.slice(params.request.startRow, params.request.endRow)
    } else {
      data.list = groupLitsData.group?.list?.slice(0, 30)
    }
  } else {
    data.list = groupLitsData.group?.list.slice(0, 30)
  }
  let rowData: any = []
  // 一级分组
  if (data?.list?.length > 0) {
    data?.list.forEach((firstLevelGroupData: any, groupOneIndex: number) => {
      const tableFirstGroupData: any = []
      tableFirstGroupData.push({
        ...firstLevelGroupData,
        index: groupOneIndex,
        groupLevel: 1,
        label: props.groupCapitalize
          ? capitalize(firstLevelGroupData?.[props.groupName])
          : firstLevelGroupData?.[props.groupName],
        firstLevelGroup: props.groupCapitalize
          ? capitalize(firstLevelGroupData?.[props.groupName])
          : firstLevelGroupData?.[props.groupName]
      })
      rowData = [...rowData, ...tableFirstGroupData]
    })
  }
  entityStore.loading = false
  return rowData
}

// 获取列表数据
const getTableGroupListData = async (params: any) => {
  let groupListParams: any = {
    page: 1,
    limit: 100
  }
  let tableGroupListData: any = []

  groupListParams = {
    page: 1,
    limit: 50
  }
  groupListParams.page = params.request.endRow / 50 ? Number((params.request.endRow / 50).toFixed(0)) : 1
  let list: any = []
  if (props.type === 'holdings') {
    list = groupLitsData.listData[params?.parentNode?.data?.index]?.platformValues
    groupLitsData.totalCount = list.length
  } else {
    list = groupLitsData.listData[params?.parentNode?.data?.index]?.list
    groupLitsData.totalCount = list.length
  }
  list?.forEach((item: any, index: number) => {
    item.rowId = `${index}${
      // eslint-disable-next-line no-nested-ternary
      item?.financialGeneralLedgerId
        ? item?.financialGeneralLedgerId
        : item?.activityId
          ? item?.activityId
          : `${index}${Math.floor(Math.random() * 900) + 100}`
    }`
  })
  tableGroupListData =
    props.type !== 'categorized' &&
    props.type !== 'review' &&
    props.type !== 'general-ledger' &&
    props.type !== 'contactsUnnamed'
      ? list?.slice(params.request.startRow, params.request.endRow)
      : list
  return tableGroupListData
}

// 获取服务器列表数据
const getServerSideDataSource = () => {
  gridApi.value?.setGridOption('serverSideDatasource', {
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        loadingCellRendererParams.loadingMessage = 'Loading'
        // 获取tableList和tableGroup数据
        if (!isGroup.value) {
          // 未分组list
          gridOptions.maxConcurrentDatasourceRequests = 2
          gridOptions.cacheBlockSize = 2
          gridApi.value?.hideOverlay()
          const tableGroupListData = await getTableGroupListData(params)
          if (Number(groupLitsData.listData?.list?.length) === 0 && props.type !== 'review' && !isDestroyed.value) {
            gridApi.value?.showNoRowsOverlay()
          }
          entityStore.loading = false
          params.success({ rowData: tableGroupListData, rowCount: groupLitsData.listData?.list?.length })
        } else if (isEmpty(params.request.groupKeys)) {
          // 一级分组框架
          gridOptions.maxConcurrentDatasourceRequests = 1
          gridOptions.cacheBlockSize = 30
          gridApi.value?.hideOverlay()

          const groupRowData = await groupRowDataFormatter(params)
          const tableGroupDataTotal: number = groupLitsData.group?.list?.length ? groupLitsData.group?.list.length : 0
          params.success({ rowData: groupRowData, rowCount: tableGroupDataTotal })
        } else {
          gridOptions.cacheBlockSize = 30
          // 一级分组下的list
          const tableGroupListData = await getTableGroupListData(params)
          params.success({
            rowData: tableGroupListData,
            rowCount: groupLitsData.totalCount
          })
        }
      } catch (error) {
        console.log(error)
        loadingCellRendererParams.loadingMessage = 'ERR'
        params?.fail()
      }
    }
  })
}

// ag-gridAPI初始化
const onGridReady = async (params: DetailGridInfo) => {
  // 获取gridApi
  gridApi.value = params?.api as GridApi
  reportStore.setAgGridApi(params?.api as GridApi)
  // 获取服务器列表数据
  getServerSideDataSource()
}

// 刷新列表
const resetList = () => {
  groupLitsData.group = {}
  groupLitsData.listData = {}
  groupLitsData.total = {}
  tableDataList.value = []
  groupLitsData.totalCount = 0
  const firstDisplayedRowIndex = gridApi.value?.getFirstDisplayedRowIndex()
  if (firstDisplayedRowIndex) {
    gridApi.value?.ensureIndexVisible(firstDisplayedRowIndex || 0, 'top')
  }
  gridApi.value?.refreshServerSide({ route: [], purge: true })
}
emitter.on('resetList', resetList)

// 当前行唯一标识
const getRowId = (params: any) => {
  // eslint-disable-next-line no-nested-ternary
  const rowId = params.data?.label
    ? `${params.data?.label}${params.data?.index}`
    : // eslint-disable-next-line no-nested-ternary
      params?.parentKeys
      ? `${params?.parentKeys[0]}${params.data?.rowId}`
      : params.data?.rowId
  return uniqueId(rowId)
}

const getRowHeight = (params: any) => {
  if ((params.node.level === 0 || params.node.level === 1) && params.data?.type === 'total') {
    return 80
  }
  return 42
}
// 是否展开分组
const isServerSideGroupOpenByDefault = (params: any) => {
  if (props.type === 'holdings' && params?.data.groupLevel < 2) return true
  return false
}

// 顶部行数据
const pinnedTopRowData = computed(() => {
  let fieldValues
  if (isGroup.value) {
    fieldValues = groupLitsData.total
  } else {
    // eslint-disable-next-line no-unused-vars
    fieldValues = groupLitsData.total
  }
  return props.type === 'holdings' ? [{ fieldValues, defaultAggregation: '', name: '' }] : null
})

defineExpose({ resetList, groupLitsData, onCheckBatchTransactions })

onBeforeUnmount(() => {
  gridApi.value?.destroy()
  gridApi.value = null
  reportStore.agGridApi = null
  emitter.off('resetList', resetList)
  emitter.off('onDeleteTableRow', onDeleteTableRow)
  emitter.off('onEditTableRowEvent', onEditTableRowEvent)
})
</script>

<style scoped lang="scss">
@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
.elv-financial-process-table {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: url('@/assets/img/screener-watermark.png') no-repeat 58%;
  background-size: 631px 200px;
  margin-left: -1px;
  padding-top: 10px;
  position: relative;

  .elv-ag-theme-alpine {
    --ag-column-border: 1px solid #e4e7eb;
    --ag-header-column-separator-color: #e4e7eb;
    --ag-header-column-separator-width: 1px;
    --ag-background-color: transparent;
    --ag-foreground-color: #0e1420;
    --ag-header-foreground-color: rgb(162, 174, 186);
    --ag-header-background-color: #f9fbfe;
    --ag-odd-row-background-color: transparent;
    // --ag-header-cell-hover-background-color: rgba(255, 255, 255 , 1)
    // --ag-odd-row-background-color: rgba(248, 250, 254, 0.5);
    --ag-font-size: 14px;
    // --ag-row-height: 42px;
    // --ag-line-height: 42px;
    --ag-list-item-height: 50px;
    --ag-header-column-resize-handle-height: 0%;
    --ag-header-column-resize-handle-width: 1px;
    --ag-grid-size: 5px;
    --ag-borders: 1px solid;
    --ag-border-color: #e4e7eb;
    --ag-row-border-color: #e4e7eb;
    // --ag-font-family: 'Barlow-Regular';
    --ag-borders-row: solid 1px;
    --ag-cell-horizontal-border: solid #e4e7eb;
    --ag-header-column-separator-display: block;
  }

  :deep(.ag-root-wrapper) {
    border-color: #cedee0;

    .ag-row-hover.ag-full-width-row.ag-row-group:before,
    .ag-row-hover:not(.ag-full-width-row):before {
      background-color: #f9fafb;
    }
  }

  :deep(.ag-horizontal-left-spacer, .ag-horizontal-right-spacer) {
    overflow-x: overlay;
  }

  :deep(.ag-body-horizontal-scroll-viewport) {
    overflow-x: overlay;
  }

  :deep(.ag-header-group-cell-with-group) :deep(.ag-header-cell-label) {
    color: rgb(44, 46, 48);
    font-size: x-small;
    justify-content: right;
  }

  :deep(.ag-header) {
    height: 36px !important;
    min-height: 36px !important;
    border-bottom-color: #cedee0;
  }

  :deep(.ag-sticky-top) {
    z-index: 999;
  }

  :deep(.ag-header-row):nth-child(1) {
    height: 36px !important;
    line-height: 34px;
    font-size: 11px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;

    color: $elv-color-9BA8B5;
    //   letter-spacing: 0.075em;
    background-color: #eef4fb;
  }

  :deep(.ag-pinned-left-header),
  :deep(.ag-pinned-left-cols-container) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);
    z-index: 100;
  }

  .ag-floating-top .ag-row {
    background-color: #000;
  }

  :deep(.ag-floating-top-viewport .ag-row) {
    border-bottom: 0px !important;
  }

  :deep(.ag-floating-bottom-viewport) {
    background: #f9fafb;
    height: 52px;

    .ag-row-pinned {
      height: 100% !important;
    }

    .ag-cell {
      height: 52px !important;
    }
  }

  .ag-floating-bottom .ag-row {
    background-color: #000;
  }

  :deep(.ag-header-cell) {
    color: $elv-color-E4E7EB;
    padding-left: 0;
    padding-right: 0;

    &::after {
      width: var(--ag-header-column-separator-width);
      background-color: var(--ag-header-column-separator-color);
    }
  }

  :deep(.ag-overlay) {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1));
  }

  :deep(.ag-floating-bottom-full-width-container) {
    height: 52px;
  }

  :deep(.ag-floating-bottom) {
    height: 52px !important;
    min-height: 52px !important;
    border-top-color: #cedee0;

    .ag-cell {
      font-family: 'Barlow' !important;
      font-weight: 700 !important;
      font-size: 12px !important;
    }
  }
  // 隐藏工具面板
  :deep(.ag-side-buttons) {
    display: none;
  }

  :deep(.ag-row) {
    .ag-cell {
      border-left-width: 0px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      display: flex;
      align-items: center;
    }
  }

  :deep(.ag-header-group-cell-label) {
    justify-content: center;
  }

  :deep(.el-button.is-text:not(.is-disabled):active) {
    background-color: $elv-color-F9FBFE;
  }

  :deep(.ag-theme-alpine .ag-tabs-header) {
    display: none;
  }

  :deep(.ag-theme-alpine .ag-tabs-body) {
    width: 150px;
    box-sizing: border-box;
  }

  :deep(.ag-tabs) {
    min-width: auto;
  }

  :deep(.ag-row-group) {
    height: 42px;
    line-height: 42px;

    .ag-cell-value {
      height: 42px !important;
      line-height: 42px !important ;
    }

    .ag-cell {
      font-family: 'Barlow' !important;
      font-weight: 700 !important;
    }
  }

  :deep(.ag-row-level-0) {
    .ag-cell-value {
      &:has(.elv-table-group-rows) {
        padding-left: 11px;
      }

      &:has(.elv-financials-total) {
        height: 80px !important;
      }

      &.ag-cell-first-right-pinned {
        &:has(.elv-financials-cell-event-main.is-pinned):hover {
          background: #edf0f3;
        }
      }
    }

    &:hover {
      background: #f9fafb;

      .elv-ag-index-select {
        display: flex;
      }
    }
  }

  :deep(.ag-row-level-0.ag-row-group) {
    background: #fbf9f7;
  }

  :deep(.ag-row-level-1.ag-row-group) {
    background: $elv-color-EFF4FF;
  }

  :deep(.ag-row-level-2.ag-row-group) {
    background: $elv-color-F5FEF4;
  }

  :deep(.ag-row-level-1) {
    .elv-table-group-rows-content__label {
      background: $elv-color-DCE6FF;
      border: 1px solid $elv-color-C4D6FF;
    }

    .ag-cell-value {
      &:has(.elv-ag-group-index) {
        border-right: 0px;
      }

      &:has(.elv-financials-total) {
        height: 80px !important;
      }

      &:has(.elv-table-group-rows) {
        padding-left: 11px;
        padding-right: 0px;
      }
    }
  }

  :deep(.ag-row-level-2) {
    .elv-ag-index {
      display: none;
    }

    .elv-table-group-rows-content__label {
      background: $elv-color-D7F7D2;
      border: 1px solid $elv-color-CCEAC7;
    }

    .ag-cell-value {
      &:has(.elv-ag-index) {
        border-right: 0px;
      }

      &:has(.elv-ag-group-index) {
        border-right: 0px;
      }

      &:has(.elv-table-group-rows) {
        padding-left: 35px;
        padding-right: 0px;
      }
    }
  }

  :deep(.ag-row-level-3) {
    .elv-ag-index {
      display: none;
    }

    .ag-cell-value {
      &:has(.elv-ag-index) {
        border-right: 0px;
      }

      &:has(.elv-table-group-rows__index) {
        padding-left: 38px;
      }
    }
  }

  :deep(.ag-row-group-contracted) {
    &:first-of-type {
      .ag-cell-value {
        padding-top: 0px;
      }
    }

    .ag-cell {
      border-width: 0px;
      border-right-width: 1px;
    }

    .elv-ag-group-arrow {
      height: 42px;
    }
  }

  :deep(.ag-pinned-right-floating-bottom) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);

    .ag-row {
      background-color: #f9fafb;
      height: 52px !important;
    }

    .ag-cell-value {
      height: 52px !important;

      &:nth-child(1) {
        border-right: 0px;
      }
    }
  }

  :deep(.ag-pinned-right-header) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);
  }

  :deep(.ag-pinned-right-cols-container) {
    position: relative;

    &::before {
      position: absolute;
      width: 4px;
      height: 100%;
      content: '';
      left: -4px;
      top: 0px;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  :deep(.ag-row-group-expanded) {
    .ag-cell {
      border-left-width: 0px;
      border-top-width: 0px;
    }
  }

  :deep(.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within) {
    border: none;
    border-right: 1px solid $elv-color-E4E7EB !important;
  }

  :deep(.ag-header-cell-comp-wrapper) {
    height: 34px;
  }

  :deep(
      .ag-pinned-left-header
        .ag-header-row-column-group
        .ag-header-group-cell-with-group:nth-child(2)
        .ag-header-group-cell-label
    ) {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    transform: scale(0.91);
    line-height: 14px;
    color: $elv-color-9BA8B5;
    justify-content: flex-start !important;
    text-transform: lowercase;
  }
}
</style>
